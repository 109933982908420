import { Router } from '@angular/router';
import { DocumentManagementService } from '../../services/document-management.service';
import { SmartTableComponent } from './smart-table.component';
import { FormioEvent } from '@evi-ui/angular/elements.common';
import { EventEmitter } from '@angular/core';

export const no_change = 'no_change';
export const API_DATA_DRIVEN = 'apiDataDriven';
export const CONTEXT_DATA_DRIVEN = 'contextDataDriven';

export class GridOptions {
  key?: string;
  title?: string; // Grid title
  gridDataContext: string; // contextDataDriven | apiDataDriven
  apiUrl?: string; // API url to load the grid data from, either of apiUrl or contextDataFieldName is mandatory
  contextDataFieldName?: string; // field name to lookup in the submission data, to load the grid data.
  refreshEnabled?: boolean;
  triggerDataFetchOnFormDataLoad?: boolean;
  headerRowClass?: string; // class name <tr> of header row
  dataRowClass?: string; // class name <tr> of data row

  styleClass?: string;
  gridHeaderClass: string;
  gridTitleClass: string;
  gridSearchControlClass: string;
  rowHover?: boolean;

  resizableColumns?: boolean; // should the columns be resizable. An internal field (option) and its automatically set to true if at least one of the columns (columnDefinitions) is true
  dynamicDataRowClass: string; //logic evaluated at runtime to deduce the class to be assigned to tr
  dynamicRowPrefix: string; //logic evaluated to attach the con to the row

  pagination?: boolean; // enable pagination
  paginationPageSize?: number; // the default pageSize (should be a possible value from paginationRowsPerPageOptions field
  paginationRowsPerPageOptions?: number[]; // the page size options that are selectable by user
  pageDataFieldName?: string; // the field name that holds the page data in api response
  /* all globalSearch prefixes should be changed to simpleText as global Search corresponds to search on complete website */
  globalSearchEnabled?: boolean; // display simpleText search text box
  globalSearchQueryFormatter?: string; // the search query formatter
  globalSearchFilterOperator?: 'contains' | 'starts_with' = 'starts_with'; // should the simpleText search be contains or starts_with
  globalSearchPlaceholder?: string;

  advancedSearchEnabled?: boolean; // enable or disable the button
  rowSpanCount: string | undefined;
  slnoColumnRequired?: boolean; // is Sl No column required
  slnoLabel?: string; // label of Sl no
  slnofield?: string | undefined; // if undefined, sl no is displayed from rowIndex. If defined, the value will be looked up in rowdata with the given field.
  slnoColumnStyle?: string; // style for slno column

  checkboxSelection?: boolean;
  radioSelection?: boolean;
  selectedRecordIdentifierField?: string;
  selectedRecordStorageField?: string;
  useCompleteRecordDataAsSelection?: boolean;

  defaultSort?: string | undefined;
  noDataLabel?: string;
  noDataLabelStyleClass?: string;
  columnDefinitions: GridColumnDefinition[];

  defaultData: any[] = [];

  needsRowExpansion: boolean = false;
  childRowsAttribute?: string;

  exportToExcel?: boolean;

  importDataEnabled?: boolean;
  importFormId?: string;
  importButtonDataLabel?: string;

  parseFileData: boolean;
  parserResultField: string;
  parserSlNoRequired: boolean;
  parserSlNoField: string;
  parser: string;
  fieldMapping: any[];
  refreshComponent: string | undefined;

  ignoreTextWithApiFieldEvents: boolean;
  isListingScreen: boolean;

  additionalDataProviders: AdditionalDataProvider[]
}

export class AdditionalDataProvider {
  // parentGridPropertyName: string;
  // keyInDataProvider: string;
  columnToKeyMapping: [];
  apiUrl: string;
  dataPath?: string;

  apiMethod: string = 'GET';
  apiRequest?: string;
  responseAction: string = 'MERGE' //Other possible values - SET FIELD VALUE
  fieldNameToStoreResult?: string;
  responseKeyToUseForMerging?: string;
  gridKeyToUseForMerging?: string;
  gridFieldToStoreResult?: string;
}
const _defaultGridOptions: GridOptions = {
  key: '',
  gridDataContext: 'apiDataDriven',
  apiUrl: '',
  contextDataFieldName: '',
  refreshEnabled: true,
  triggerDataFetchOnFormDataLoad: false,
  headerRowClass: '',
  dataRowClass: '',
  styleClass: 'p-datatable-striped p-datatable-sm',
  gridHeaderClass: ' mt-4',
  gridTitleClass: ' mb-3',
  gridSearchControlClass: ' mb-3',
  rowHover: true,
  resizableColumns: true,
  dynamicDataRowClass: '',
  dynamicRowPrefix: '',
  pagination: true,
  paginationPageSize: 10,
  paginationRowsPerPageOptions: [10, 25, 50],
  pageDataFieldName: 'content',
  globalSearchEnabled: false,
  globalSearchQueryFormatter: '',
  globalSearchFilterOperator: 'starts_with',
  globalSearchPlaceholder: 'Search...',

  advancedSearchEnabled: true,
  rowSpanCount: undefined,
  slnoColumnRequired: true,
  slnoLabel: 'S No',
  slnofield: undefined,
  slnoColumnStyle: 'width: 4rem',

  checkboxSelection: false,
  radioSelection: false,
  selectedRecordIdentifierField: 'id',
  selectedRecordStorageField: '',
  useCompleteRecordDataAsSelection: false,

  defaultSort: undefined,

  noDataLabel: 'No Records found',
  noDataLabelStyleClass: 'text-center',
  columnDefinitions: [], // Default column definitions
  defaultData: [],
  needsRowExpansion: false,
  childRowsAttribute: 'content',

  importDataEnabled: false,
  importFormId: '',
  importButtonDataLabel: '',
  exportToExcel: false,

  parseFileData: true,
  parserResultField: 'parsedResults',
  parserSlNoRequired: true,
  parserSlNoField: 'slNo',
  parser: 'xlcsvparser',
  fieldMapping: [],
  refreshComponent: undefined,
  ignoreTextWithApiFieldEvents: false,
  isListingScreen: false,
  additionalDataProviders: []
};

const _defaultChildGridOptions: GridOptions = {
  ..._defaultGridOptions,
  refreshEnabled: false,
  rowHover: true,
  resizableColumns: false,
  dynamicDataRowClass: '',
  pagination: false,
  advancedSearchEnabled: false,

  slnoColumnRequired: false,
  slnoLabel: 'S No',
  slnofield: undefined,
  slnoColumnStyle: 'width: 4rem',

  noDataLabel: 'No Records found',
  noDataLabelStyleClass: 'text-center',
  needsRowExpansion: true,
  childRowsAttribute: 'content',

  exportToExcel: false,
  parseFileData: false
};

export class GridColumnDefinition {
  header?: string;
  headerName?: string;
  headerTooltip?: string;
  headerClass?: string;
  thStyle?: string; //min-width:  14rem
  tdStyle?: string;
  field: string;

  compositeColumn?: boolean; //  column defined based on other columns
  valueFormatterTemplate?: string | undefined; // lodash Template code, used to compute the raw values based on some logic
  displayValueFormatters?: DisplayFormatter[] | undefined; // lodash Template code for displaying purpose. if present, defaultDisplayFormatter will not be used to format the data
  sortField?: string; // used to specify and different field other the field, in case of composite column

  cellDataType: string = 'string'; // possible values = > 'string' | 'boolean' | 'number' | 'currency' | 'date'   = <string>
  sortable?: boolean;
  hide?: boolean;
  tooltipField?: string;
  width?: string;
  flex?: number;
  resizable?: boolean;
  formatOptions?: FormatOptions; //define FormatOptions for each cell DataType

  defaultValueIfNull?: string;
  defaultDisplayFormatter?(
    data: any,
    cellDataRendererContext: CellDataRendererContext
  ): any;

  tooltipValueGetter?(value: any): string;
}

const _defaultColumnDefinition = {
  headerName: '',
  headerTooltip: '',
  headerClass: '',
  thStyle: '',
  tdStyle: '',
  field: '',
  cellDataType: <string>'string',
  sortable: true,
  hide: false,
  flex: 1,
  resizable: true,
  defaultValueIfNull: '--'
};

export interface DisplayFormatter {
  category:
    | 'popup'
    | 'navigation'
    | 'download'
    | 'no_action'
    | 'delete_row'
    | 'edit_row'
    | undefined;
  displayType: 'button' | 'html';
  iconClass: string;
  styleClass: string;
  toolTip: string;
  displayValueFormatterTemplate: string | undefined;
  useFormattedValueAsActionInput: boolean;
  actionValueFormatterTemplate: string | undefined;
  popupScreenId?: string;
  downloadId?: string;
  actionUrl?: string;
  stateToPropagateEvaluator?: string;
  conditionallyDisable?: string;
  toolTipWhenDisabled?: string;

  handler?(
    cellDataActionContext: CellActionContext,
    displayFormatter: DisplayFormatter
  ): any;
}

export interface FormatOptions {
  /* common formatters options*/
  prefix?: string;
  suffix?: string;
  digitsInfo?: string;

  localeId?: string;

  /* String formatter */
  case?: 'upper_case' | 'lower_case' | 'title_case' | 'no_change';

  /* Boolean formatter */
  booleanFormat?: 'yes_no' | 'true_false' | '1_0';

  /* Date Formatter  - use moment lib.  js fiddle ref for test cases -> https://jsfiddle.net/dstv5x3u/2/ */
  enableDateFormatter: boolean;
  sourceFormat?: string; // ISO 8601 is default format
  targetFormat?: string;

  /* Number formatter */
  disableNumberFormatter: boolean;
  numberFormat?: 'decimal' | 'percentage';
  decimalPlaces: number;

  /* currency formatter */
  currencyCode?: string; // ISO 4217 currency code
  currency?: string; // 'symbol' or 'code' or 'symbol-narrow' or our own string
  /* String formatter */
}

const _defaultFormatOptions = {
  prefix: '',
  suffix: '',
  digitsInfo: '1.2-2',
  localeId: 'en-SG',
  case: no_change,

  booleanFormat: 'yes_no',

  enableDateFormatter: false,
  sourceFormat: 'YYYY-MM-DD HH:mm:ss',
  targetFormat: 'DD-MM-YYYY',

  disableNumberFormatter: false,
  numberFormat: 'decimal',
  decimalPlaces: 2,

  currencyCode: 'SGD',
  currency: 'S$ '
};

export class GridAction {
  iconClass: string;
  toolTip: string;
  category: 'popup' | 'navigation' | 'download' = 'navigation';
  popupScreenId?: string;
  actionUrl?: string;

  handler?(cellDataActionContext: CellActionContext): any;
}

export interface CellDataRendererContext {
  index: number;
  columnDef: GridColumnDefinition;
  rowData: any;
  rowIndex: number;
}

export interface CellActionContext {
  actionValue: string;
  gridData: any[]; // complete grid data
  rowData: any;
  router: Router;
  downloadService: DocumentManagementService; //TODO: This should NOT be hard bound to the APIs
  gridOptions: GridOptions;
  formioDataSubmission: any;
  formioInstance?: any;
  smartTableInstance: SmartTableComponent;
  formioEvent: EventEmitter<FormioEvent>;
}

/**
 * Manage defaults for GridOptions, This will handle columnDefinitions and gridAction child Objects. If any new child Objects are added to GridOptions, ensure you merge them manually
 * @param gridOptions
 */

export function mergeGridOptionsWithDefaults(
  gridOptions: Partial<GridOptions>,
  isChildGrid = false
): GridOptions {
  const mergedOptions: GridOptions = isChildGrid
    ? { ..._defaultChildGridOptions, ...gridOptions }
    : { ..._defaultGridOptions, ...gridOptions };

  // If customColumnDefinitions are provided in gridOptions, merge them
  if (gridOptions.columnDefinitions) {
    mergedOptions.columnDefinitions = mergeGridColumnDefinitionsWithDefaults(
      gridOptions.columnDefinitions
    );
  }

  // set parent option based on child for resizable
  // no more column level resizing, apply at table level (all columns)
 /* if (
    gridOptions.columnDefinitions &&
    gridOptions.columnDefinitions.some((colDef) => colDef.resizable === true)
  ) {
    mergedOptions.resizableColumns = true;
  }*/

  /*if(mergedOptions.needsRowExpansion && gridOptions?.expansionRowGridOptions) {
    mergedOptions.expansionRowGridOptions = mergeGridOptionsWithDefaults(<any>gridOptions?.expansionRowGridOptions);
  }*/
  return mergedOptions;
}

export function mergeGridColumnDefinitionsWithDefaults(
  columnDefinitions: Partial<GridColumnDefinition>[]
): GridColumnDefinition[] {
  return columnDefinitions
    .map((column) => ({
      ..._defaultColumnDefinition,
      ...column
    }))
    .map((columnDef) => {
      columnDef.header = columnDef.headerName; // headerName alias for header

      columnDef.formatOptions = mergeFormatOptionsWithDefaults(
        columnDef.formatOptions || {}
      );

      return columnDef;
    });
}

export function mergeFormatOptionsWithDefaults(
  formatOptions: Partial<FormatOptions>
): FormatOptions {
  return <FormatOptions>{
    ..._defaultFormatOptions,
    ...formatOptions
  };
}
