import {CellActionContext, DisplayFormatter} from './smart-table.model';
import FileSaver from 'file-saver';
import {LodashTemplateEvaluator} from './LodashTemplateEvaluator';
import {FormRendererUtils} from '../../../portal/application-forms/all-forms-renderer/form-renderer-utils';
import * as _ from 'lodash';
import {
  CustomButtonActionHandlerContext
} from "@shared/components/custom-action-button/handlers/custom-button-action-handler";
import {ActionProcessor, PopupAction} from "@shared/components/custom-action-button/custom-action-button-model";

export function attachActionHandler(displayValueFormatter: DisplayFormatter) {
  switch (displayValueFormatter.category) {
    case 'popup':
      displayValueFormatter.handler = popupActionHandler;
      break;
    case 'navigation':
      displayValueFormatter.handler = navigationActionHandler;
      break;
    case 'download':
      displayValueFormatter.handler = downloadActionHandler;
      break;
    case 'delete_row':
      displayValueFormatter.handler = deleteRowActionHandler;
      break;
    case 'edit_row':
      displayValueFormatter.handler = editRowActionHandler;
      break;
    // Add more cases for other data types
  }
}

export function navigationActionHandler(
  cellActionContext: CellActionContext,
  displayFormatter?: DisplayFormatter
) {
  let navigationUrl = cellActionContext.actionValue;
  let stateToPropagate = {};
  if (displayFormatter?.stateToPropagateEvaluator) {
    console.log('Interpolating & Evaluating', displayFormatter);
    let scriptValue = LodashTemplateEvaluator.interpolate(
      displayFormatter?.stateToPropagateEvaluator,
      cellActionContext
    );
    stateToPropagate = FormRendererUtils.evaluate(
      scriptValue,
      cellActionContext,
      'stateToPropagate',
      true
    );

    navigationUrl = LodashTemplateEvaluator.interpolate(
      navigationUrl,
      cellActionContext.formioDataSubmission
    );

    //  navigationUrl = FormRendererUtils.evaluate(navigationUrl, cellActionContext, "navigationUrl", true);
    const evaluatedNavigationUrl = FormRendererUtils.evaluate(
      navigationUrl,
      cellActionContext,
      'navigationUrl',
      true
    );
    console.log('evaluatedNavigationUrl => ', evaluatedNavigationUrl);
    if (
      !(_.isNil(evaluatedNavigationUrl) || _.isEmpty(evaluatedNavigationUrl))
    ) {
      console.log(
        'As evaluated navigationUrl is empty, using the interpolated version',
        navigationUrl
      );
      navigationUrl = evaluatedNavigationUrl;
    }
  }
  stateToPropagate['bwayparam'] = cellActionContext.smartTableInstance.searchState;

  console.log('Navigating to ', navigationUrl);
  if (displayFormatter?.stateToPropagateEvaluator) {
    cellActionContext.router.navigate([navigationUrl as string], {
      state: { state: stateToPropagate }
    });
  } else {
    //console.error("No navigation URL configured for the row action button")
    cellActionContext.router
      .navigate([navigationUrl as string], {
        state: { state: stateToPropagate }
      })
      .then((r) => console.log('Navigation completed from smart grid'));
  }
}

export function deleteRowActionHandler(cellActionContext: CellActionContext) {
  deleteRowData(cellActionContext);

  //delete cellActionContext.gridData[rowIndex];

  let key = cellActionContext.gridOptions.key;

  const eventName = `smart-table-${key}-delete_row`;
  cellActionContext.formioEvent.emit({
    eventName,
    data: {
      rowData: cellActionContext.rowData,
      key: key
    }
  });
}

export function editRowActionHandler(cellActionContext: CellActionContext) {
  let key = cellActionContext.gridOptions.key;

  const eventName = `smart-table-${key}-edit_row`;
  const eventPayload = {
    data: {
      rowData: cellActionContext.rowData,
      key: key
    }
  };
  console.log(
    `Emitting Edit event for Key -> ${eventName}  with Payload -> ${JSON.stringify(eventPayload)}`
  );

  cellActionContext.formioEvent.emit({
    eventName,
    data: {
      rowData: cellActionContext.rowData,
      key: key
    }
  });
}

function deleteRowData(cellActionContext: CellActionContext) {
  let rowIndex = +cellActionContext.actionValue;
  const gridData = cellActionContext.gridData;
  const contextDataFieldName =
    cellActionContext.gridOptions.contextDataFieldName;

  //gridData.splice(rowIndex, 1);
  if (contextDataFieldName) {
    cellActionContext.formioDataSubmission[contextDataFieldName].splice(
      rowIndex,
      1
    );
    cellActionContext.smartTableInstance.refreshGrid();
  }
}

export function popupActionHandler(cellActionContext: CellActionContext,
                                   displayFormatter?: DisplayFormatter) {
  let customButtonActionHandlerContext = new CustomButtonActionHandlerContext();
  customButtonActionHandlerContext.formInstance = cellActionContext.formioInstance;
  customButtonActionHandlerContext.formInstance.rowData = cellActionContext.rowData;
  customButtonActionHandlerContext.formData = cellActionContext.rowData;
  customButtonActionHandlerContext.formioEvent = cellActionContext.formioEvent;

  let actionProcessor = new ActionProcessor();
  actionProcessor.popupAction = new PopupAction();
  actionProcessor.popupAction.inputContextVariable = 'rowData';
  actionProcessor.popupAction.popupType = 'custom';
  actionProcessor.popupAction.screenId = displayFormatter?.popupScreenId!!;
  customButtonActionHandlerContext.actionProcessor = actionProcessor;
  const eventName = `smart-table-${actionProcessor.popupAction.screenId}-closed`;
  cellActionContext.smartTableInstance.showPopupActionProcessor.performAction(customButtonActionHandlerContext).then(actionResult => {
    cellActionContext.formioEvent.emit({
      eventName,
      data: {
        popupResult: actionResult
      }
    });
  });
}

function downloadActionHandler(cellActionContext: CellActionContext) {
  console.log('downloadActionHandler -> ' + cellActionContext);
  let downloadUrl = cellActionContext.actionValue;
  console.log('downloadUrl -> ' + downloadUrl);
  FileSaver.saveAs(downloadUrl);
}
