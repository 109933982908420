/** Usage
 * SpinnerUtil.activateSpinner(); // Activates the spinner with id "spinner"
 * SpinnerUtil.deactivateSpinner(); // Deactivates the spinner with id "spinner"
 */
export class SpinnerUtil {

  /**
   * shows the spinner icon (round red circle) with no text
   * @param spinnerId
   */
  static activateSpinner(spinnerId = "spinner") {
    this.showSpinner(spinnerId);
   // this.showLoader();
  }



  static deactivateSpinner(spinnerId = "spinner") {
    this.hideSpinner(spinnerId);
    //this.hideLoader();
  }


  private static showSpinner(spinnerId: string) {
    const spinner = document.getElementById(spinnerId);
    if (spinner) {
      spinner.removeAttribute("hidden");
    }
  }

  private static hideSpinner(spinnerId: string) {
    const spinner = document.getElementById(spinnerId);
    if (spinner) {
      spinner.setAttribute("hidden", "");
    }
  }

  /**
   * shows the Loading icon with Loading... text
   */
  static showLoader() {
// Cast to HTMLElement
    (document.querySelector(".loader-div") as HTMLElement).style.display = "block";
  }

  static hideLoader() {
    // Cast to HTMLElement
    (document.querySelector(".loader-div") as HTMLElement).style.display = "none";


  }

}



