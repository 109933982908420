import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../../shared/services/storage.service';
import { FormService } from '../form.service';
import { Formio, Utils as FormioUtils } from '@evi-ui/js';
import { LodashTemplateEvaluator } from '../../../shared/components/smart-table/LodashTemplateEvaluator';
import { FormRendererUtils } from '../../application-forms/all-forms-renderer/form-renderer-utils';
import { IamAuthUtils } from '@shared/utils/iam-auth-utils';
import _ from 'lodash';
import { EventBusService } from '@shared/event-bus.service';
import { EventData, EventType } from '@shared/model/event.data';

@Component({
  selector: 'app-dialog-component',
  templateUrl: './submit-dialog.component.html',
  styleUrls: ['./submit-dialog.component.scss']
})
export class SubmitDialogComponent implements OnInit, OnDestroy {
  formId: any;
  returnUrl: any;
  popupTitle: any;
  popupContent: any;
  id: any;
  public form: any = { title: '', components: [] };

  private _formInstance;
  refreshForm = new EventEmitter();

  private processedResult: any = null;

  private _data?: any;
  private parentInputData: any;
  private closeRefOnDestroy: boolean = false;

  constructor(
    private router: Router,
    private eventBusService: EventBusService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private formService: FormService,
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {}

  ngOnInit() {
    this.formId = this.dialogConfig.data.formId;
    this.returnUrl = this.dialogConfig.data.returnUrl && this.dialogConfig.data.inAppNavigation
      ? '/app/' + this.dialogConfig.data.returnUrl : this.dialogConfig.data.returnUrl;
    this._data = this.dialogConfig.data.inputData; //experimental -  thinking having data in place first, when the html is changes, expressions will be resolved and doesn't give ExpressionChanged error.
    this.popupTitle = this.dialogConfig.data.title;
    this.popupContent = this.dialogConfig.data.description;
    this.parentInputData = this.dialogConfig.data.parentInputData;
    this.closeRefOnDestroy = this.dialogConfig.data.closeRefOnDestroy;
    console.log(
      'initialising the dialog component:: this.dialogConfig',
      this.dialogConfig
    );
    this.renderForm();
  }

  onSubmit(event: any) {
    console.log('Form submitted in popup :: event is  => ', event);
    if (event.data.action == 'Cancel' || event.data.cancel) {
      //Cancel was clicked.
      this.ref.close();
      return;
    }
    console.log(
      'Form submitted in popup :: return url is  => ',
      this.returnUrl
    );
    if (this.returnUrl) {
      //TODO: change logic here
      console.log('Form submitted in popup ::ref is  => ', this.ref);
      if (this.ref) {
        console.log('Form submitted in popup ::closing the popup');
        this.ref.close();
      }
      console.log('Form submitted in popup ::navigating to =>', this.returnUrl);
      if (this.dialogConfig.data.inAppNavigation) {
        this.router.navigate([this.returnUrl]);
      } else {
        window.location.href = this.returnUrl;
      }
    } else {
      console.log(
        'Form submitted in popup ::probably return url is empty =>',
        this.returnUrl
      );
      console.log(
        'Popup Closed: ',
        this.processedResult,
        this._formInstance.submission.data
      );
      if (this.ref) {
        this.ref.close(
          this.processedResult != null
            ? this.processedResult
            : this._formInstance.submission.data
        );
      } else {
        console.log('No refs found');
      }
    }
  }

  private renderForm() {
    this.formService.getById(this.formId).subscribe((data) => {
      if (data?.components) {
        this.form.title = data.name;
        let formData = data?.components[0]?.replace(
          '<title-name>',
          this.popupTitle
        );
        formData = formData.replace(
          '<content-description></content-description>',
          this.popupContent
        );
        this.form.components = JSON.parse(<string>formData);

        let _self = this;

        FormRendererUtils.addAuthHeaderOnSelectComponents(this.form.components);
        Formio.Headers(
          new Headers({
            Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
          })
        );

        Formio.createForm(
          document.getElementById('formio-popup'),
          {
            components: this.form.components
          },
          {
            disableAlerts: true,
            noAlerts: true,
            noValidate: true,
            hooks: {
              beforeSubmit: (submission, next) => {

                try {
                  console.log(
                    'Form submitted in popup ::beforeSubmit Hook =>',
                    submission
                  );
                  _self.performBeforeSubmit(submission);
                  next();
                } catch (error: any) {
                  console.error('Error in beforeSubmit Hook:', error);

                  // Stop submission and pass the error message to Formio
                  next(error.message || 'An unexpected error occurred.');
                }


              }
            }
          }
        ).then((formInstance) => {
          if (this._data) {
            formInstance.nosubmit = true;
            formInstance.submission = { data: this._data };
            formInstance.submission['data']['inputData'] = this._data;
            formInstance['data']['inputData'] = this._data;
            if (this.returnUrl) {
              formInstance.submission['data']['successNavigationUrl'] =
                this.returnUrl;
              formInstance['data']['successNavigationUrl'] = this.returnUrl;
            }
          } else {
            console.log('No input data provided for popup ', this.formId);
          }
          if (this.parentInputData) {
            /*formInstance.submission['data']['parentInputData'] =
              this.parentInputData;
            formInstance['data']['parentInputData'] = this.parentInputData;*/
          } else {
            console.log('No parentInputData provided for popup');
          }
          formInstance.on('submit', function (submission) {
            console.log('onSubmit Invoked');
            _self.onSubmit(submission);
          });
          _self._formInstance = formInstance;
          /*_self.eventBusService.emit(
            EventData.of(EventType.FORM_DATA_LOADED, formInstance.data)
          );*/
          _self._formInstance.nosubmit = true;
          this.form.inputData = this._data;
          this.refreshForm.emit({
            form: this.form
          });
        }).catch((error) => {
          // Handle errors that occur during Formio.createForm
          console.error('Error occurred while creating popup content form:', error);
          // You can also show an error message to the user or take specific action
          alert('An error occurred while loading the form. Please try again.');
        });
      }
    });
  }

  private performBeforeSubmit(submission: any) {
    let clickedButton = submission.component;
    submission = this._formInstance.submission;
    if (clickedButton.beforeSubmitHook) {
      let actionValue = LodashTemplateEvaluator.interpolate(
        clickedButton.beforeSubmitHook,
        submission
      );
      let evaluationResult = FormioUtils.evaluate(
        actionValue,
        submission,
        'data'
      );
      this.processedResult = evaluationResult;
    }
  }

  ngOnDestroy() {
    let thisPopupData = _.cloneDeep(this._formInstance.submission?.data);
    delete thisPopupData.inputData;
    this._formInstance.submission = {};
    console.log('Destroying SubmitDialog Component => ', this.popupTitle);
    if (this.closeRefOnDestroy) {
      this.ref.close(thisPopupData);
    }
  }
}
