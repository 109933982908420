import {SubmitSuccessPopup} from './screen-config';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {SubmitDialogComponent} from '../../forms/submit-dialog/submit-dialog.component';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {JSONPath} from 'jsonpath-plus';
import {PopupConfig} from '@shared/components/custom-action-button/custom-action-button-model';
import {IamAuthUtils} from '@shared/utils/iam-auth-utils';
import {Formio, Utils as FormioUtils} from '@evi-ui/js';
import moment from 'moment';
import {AclUtils} from '../../../iam/iam_utils/acl-utils';
import {DynamicDialogConfig} from 'primeng/dynamicdialog/dynamicdialog-config';
import {
  ActionChain,
  ActionListener,
  ActionType,
  ApiConfig,
  FieldValues,
  ScriptExecutionConfig,
  SmartTableActionConfig
} from '@shared/components/screen-configuration/screen-configuration.model';
import {LodashTemplateEvaluator} from '@shared/components/smart-table/LodashTemplateEvaluator';
import {firstValueFrom} from 'rxjs';
import {FormIODataTypes} from "@shared/model/enum/global-enum";
import {AnalyticsConfig} from "@shared/components/embedded-report/embedded-report.model";

@Injectable({
  providedIn: 'root'
})
export class FormRendererUtils {
  constructor(private dialogService: DialogService) {
  }

  public showPopupWithPromise(popupConfig: PopupConfig, submissionData: any) {
    const config = {
      width: popupConfig.width,
      duplicate: true,
      closable: false,
      modal: true,
      showHeader: false,
      contentStyle: {
        padding: 0
      },
      baseZIndex: 10000,
      data: {
        formId: popupConfig.popupScreenId,
        title: popupConfig.headerMessage || 'Message',
        description: popupConfig.message || '',
        inputData: _.cloneDeep(popupConfig.hasOwnProperty("data") ? popupConfig['data'] : submissionData),
        parentInputData: _.cloneDeep(popupConfig.parentInputData),
        closeRefOnDestroy: popupConfig.closeRefOnDestroy
      }
    };
    _.set(config, 'contentStyle.overflow', 'visible'); //IFAS-940 global fix for all popups
  //  _.set(config, 'contentStyle.overflow-y', 'auto'); // redundant and breaking the UI in cashier module popup
    _.set(config, 'contentStyle.max-height', '80vh');

    config['duplicate'] = true; //this is not required for 16.1.2 version, only auto upgraded version need this

    if (popupConfig.contentStyle) {
      config.contentStyle = popupConfig.contentStyle;
    }
    const dialogRef: DynamicDialogRef = this.dialogService.open(
      SubmitDialogComponent,
      config
    );

    if (dialogRef) {
      const promise = firstValueFrom(dialogRef?.onClose);
      return promise.then((r) => {
        dialogRef.destroy();
        return Promise.resolve(r);
      });
    } else {
      console.log('showPopupWithPromise :: Rejected');
      return Promise.reject();
    }
  }

  //TODO: Remove this method and use the one that has the same code but returns the Promise
  public showPopup(popupConfig: PopupConfig, submissionData: any): void {
    const dialogRef: DynamicDialogRef = this.dialogService.open(
      SubmitDialogComponent,
      {
        width: '45%',
        closable: false,
        modal: true,
        showHeader: false,
        contentStyle: {
          padding: 0
        },
        baseZIndex: 10000,
        data: {
          formId: popupConfig.popupScreenId,
          title: popupConfig.headerMessage || 'Message',
          description: popupConfig.message || '',
          inputData: _.cloneDeep(submissionData)
        }
      }
    );

    if (dialogRef) {
      dialogRef?.onClose.subscribe((result: any) => {
        if (
          popupConfig.returnValueStorageAttribute &&
          Object.keys(result).length
        ) {
          const existingData = _.get(
            submissionData,
            popupConfig.returnValueStorageAttribute
          );
          if (existingData) {
            result = _.merge(existingData, result);
          }
          _.set(
            submissionData,
            popupConfig.returnValueStorageAttribute,
            result
          );
        }
      });
    }
  }

  public openPopup(popupConfig: SubmitSuccessPopup): void {
    const defaultConfig = {
      width: '45%',
      closable: false,
      modal: true,
      showHeader: false,
      contentStyle: {
        padding: 0
      },
      baseZIndex: 10000
    };

    this.openPopupWithUIConfig(popupConfig, defaultConfig).then((res) => res);
  }

  public openPopupWithUIConfig(
    popupConfig: SubmitSuccessPopup,
    config: DynamicDialogConfig,
    submissionData?: any
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      config.data = {
        formId: popupConfig.popupScreenId,
        title: popupConfig.headerMessage
          ? popupConfig.headerMessage
          : 'Message',
        description: popupConfig.message ? popupConfig.message : '',
        returnUrl: popupConfig.redirectUrlAfterSubmission,
        inputData: popupConfig.data,
        inAppNavigation: popupConfig.inAppNavigation
      };

      _.set(config, 'contentStyle.overflow', 'visible'); //IFAS-940 global fix for all popups

      //workflow popup issue, the dialogs are cached and should allow duplicate to avoid reusability, as its returning nul in case same popupComponent present.
      // https://github.com/primefaces/primeng/blob/3bb279a38564dbf4a4a51595e974749cbffebce0/src/app/components/dynamicdialog/dialogservice.ts#L14
      //https://github.com/primefaces/primeng/blob/3bb279a38564dbf4a4a51595e974749cbffebce0/src/app/components/dynamicdialog/dialogservice.ts#L87

      config['duplicate'] = true; //this is not required for 16.1.2 version, only auto upgraded version need this
      const dialogRef: DynamicDialogRef = this.dialogService.open(
        SubmitDialogComponent,
        config
      );

      dialogRef.onClose.subscribe((result: any) => {
        console.log('Popup Closed: ', result);
        if (
          submissionData &&
          popupConfig.returnValueStorageAttribute &&
          result &&
          Object.keys(result).length
        ) {
          const existingData = _.get(
            submissionData,
            popupConfig.returnValueStorageAttribute
          );
          if (existingData) {
            console.log('existing data and merging the result', existingData);
            result = _.merge(existingData, result);
          }
          _.set(
            submissionData.data,
            popupConfig.returnValueStorageAttribute,
            result
          );
          console.log('resolving the  result from dialog close', result);
          resolve(result);
        }
      });
    });
  }

  /**
   * This method will resolve the variables that are nested and repetitive. For example in below JSON,
   * {
   *   "salesOrder": {
   *     "orderNumber": "1235",
   *     "orderDetails": [
   *       "lineItem: {
   *         "productId": "1236",
   *         "description": "Product 1"
   *       },
   *       "lineItem: {
   *         "productId": "7890",
   *         "description": "Product 2"
   *       }
   *     ]
   *   }
   * }
   *
   * productIds can be retrieved as salesOrder.lineItem[*].productId
   * It uses jsonpath-plus library to resolve the JSON node.
   * Refer https://registry.npmjs.org/jsonpath-plus for details of the library.
   *
   * @param template Template that needs to be resolved
   * @param data Data that can be used to assign value to the resolved variables.
   */
  public static resolveRepeatingParameters(template, data) {
    const templateCopy = String(template);
    let returnValue = String(template);
    // @ts-ignore
    const match = templateCopy.match(/({{\s*(.*?)\s*}})/g);
    if (match) {
      match.forEach((substitutionParameter) => {
        let x = substitutionParameter;
        x = x.slice(3, -2);
        x = x.substring(0, x.lastIndexOf('.') - 1); //TODO: This assumes that there will be a method on aggregated data
        const jsonPath = x;
        const jsonValues = JSONPath({path: jsonPath, json: data});
        if (jsonValues && jsonValues.length) {
          const split = substitutionParameter.split(x);
          const newValue = split[0] + jsonValues.toString() + split[1];
          const evaluatedValue = eval(newValue);
          const splitTemplate = returnValue.split(substitutionParameter);
          returnValue =
            splitTemplate[0] +
            encodeURIComponent(evaluatedValue) +
            (splitTemplate.length > 1 ? splitTemplate[1] : '');
        }
      });
    }
    return returnValue;
  }

  public static nullifyRecursively(obj) {
    for (const k in obj) {
      if (obj[k] == null) continue;
      if (typeof obj[k] == 'object' && obj[k] !== null) {
        FormRendererUtils.nullifyRecursively(obj[k]);
      }
      if (typeof obj[k] == 'object' && Object.keys(obj[k]).length == 0) {
        obj[k] = null;
      }
      if (obj[k] != null && typeof obj[k] == 'object') {
        const isEmpty = Object.values(obj[k]).every(
          (x) => x === null || x === ''
        );
        if (isEmpty) {
          obj[k] = null;
        }
      }
      if (typeof obj[k] == 'string' && _.isEmpty(obj[k])) {
        obj[k] = null;
      }
    }
  }

  public static addAuthHeaderOnSelectComponents(components: any[]) {
    components.forEach((component) => {
      if (component.type == 'select') {
        if (!component.data) {
          component.data = {};
        }
        if (!component.data.headers) {
          component.data.headers = [];
        }
        component.data.headers.push({
          key: 'Authorization',
          value: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
        });
      }
      if (component.components && FormioUtils.isLayoutComponent(component)) {
        FormRendererUtils.addAuthHeaderOnSelectComponents(component.components);
      } else if (component.type === 'columns' && component.columns) {
        FormRendererUtils.addAuthHeaderOnSelectComponents(component.columns);
      }
    });
  }

  public checkAclPermission(components: any[]) {
    components.forEach((component) => {
      if (component.aclResource) {

        const aclKey = component.aclResource;

        const hasAccess = AclUtils.hasAccess(aclKey);
        console.log(`${aclKey} => hasAccess ?? ${hasAccess} => `);
        if (!hasAccess) {
         this.hideButtons([component]);
         try {
           this.hideComponent(component); //hide container component
         }catch (e) {
           console.log("Error while hiding component for acl : " + aclKey)
         }

        }
      }
      if (component.components && FormioUtils.isLayoutComponent(component)) {
      //  console.log("Component isLayoutComponent")
        this.checkAclPermission(component.components);
      } else if (component.type === 'columns' && component.columns) {
       // console.log("Component component.type === 'columns'")
        this.checkAclPermission(component.columns);
      } else {
        //console.log("Component met no condition for ACL")
      }
    });
  }

  public static getComponent(componentKey: string, components: any[]) {
    let returnMe;
    for (const component of components) {
      if (component.key === componentKey) {
        returnMe = component;
      }
      if (component.components && FormioUtils.isLayoutComponent(component)) {
        if (component.component && component.component.key === componentKey) {
          returnMe = component;
        } else {
          returnMe = FormRendererUtils.getComponent(
            componentKey,
            component.components
          );
        }
      }
      if (component.type === 'columns' && component.columns) {
        if (component.component && component.component.key === componentKey) {
          returnMe = component;
        } else {
          returnMe = FormRendererUtils.getComponent(
            componentKey,
            component.columns
          );
        }
      }

      if (returnMe) {
        return returnMe;
      }
    }
  }

  public static eachComponent(components: any[], fn: any) {
    for (const component of components) {
      fn(component);
      if (component.components && FormioUtils.isLayoutComponent(component)) {
        FormRendererUtils.eachComponent(
          component.components,
          fn
        );
      }

      if (component.type === 'columns' && component.columns) {
        FormRendererUtils.eachComponent(
          component.columns,
          fn
        );
      }

    }
  }

  public static getValue(submissionData: any, componentKey: string, defaultValue = '') {
    return _.get(submissionData, componentKey, defaultValue);
  }

  public static getComponentByType(
    componentType: string,
    components: any[],
    returnValues: any[] = [],
    parentComponent: any = undefined
  ): any[] {
    for (const component of components) {
      if (component.type === componentType) {
        returnValues.push({
          component: component,
          parentComponent: parentComponent
        });
      }
      if (component.components && FormioUtils.isLayoutComponent(component)) {
        FormRendererUtils.getComponentByType(
          componentType,
          component.components,
          returnValues,
          component
        );
      } else if (component.type === 'columns' && component.columns) {
        FormRendererUtils.getComponentByType(
          componentType,
          component.columns,
          returnValues,
          component
        );
      }
    }
    return returnValues;
  }

  //TODO: This is a wrong place to build the evaluation context. For example, the instance and self point to this util class, which is of no use.
  public static evalContext(additional) {
    const contextArray = Array.isArray(additional) ? additional : [additional];
    const contextObjects = contextArray.map((obj) => ({
      _,
      utils: FormioUtils,
      util: FormioUtils,
      moment,
      instance: this,
      self: this,
      ...obj
    }));

    return Object.assign({}, ...contextObjects);
  }

  public static evaluate(func, additional, returnAttribute, tokenize = true) {
    return FormioUtils.evaluate(
      func,
      FormRendererUtils.evalContext(additional),
      returnAttribute,
      tokenize
    );
  }

  /**
   * updates the smart table with the given value. Smart table will also updates the submission context, to ensure data is not lost in subsequent reloads.
   * @param formComponents
   * @param tableKey
   * @param value
   */
  public static setDataOnSmartTable(
    formComponents: any,
    tableKey: any,
    value: any
  ) {
    FormioUtils.findComponent(formComponents, tableKey, '', (component) => {
      console.log(component.key, ' ', component.type, value);
      component.setValue({data: value});
    });
  }

  /**
   * reloads the data from the context. avoid using this method as much as possible as smart table is not getting the live copy  of data (submission.data) at times.
   * Use setDataOnSmartTable where ever possible, as it just update the smart table with data being set on it
   * @param formComponents
   * @param tableKey
   */
  public reloadSmartTable(formComponents: any, tableKey: any) {
    FormioUtils.findComponent(formComponents, tableKey, '', (component) => {
      //  component.setValue({data: value});
      component.setValue({
        action: 'refresh'
      });
    });
  }

  public openConfirmationPopup(popupConfig: PopupConfig): any {
    const dialogRef: DynamicDialogRef = this.dialogService.open(
      SubmitDialogComponent,
      {
        width: '45%',
        closable: false,
        modal: true,
        showHeader: false,
        contentStyle: {
          padding: 0
        },
        baseZIndex: 10000,
        data: {
          formId: popupConfig.popupScreenId,
          title: popupConfig.headerMessage
            ? popupConfig.headerMessage
            : 'Message',
          description: popupConfig.message ? popupConfig.message : ''
        }
      }
    );
    return dialogRef;
  }

  public hideButtons(components: any[]) {
    for (const component of components) {
      if (component.components && FormioUtils.isLayoutComponent(component)) {
        this.hideButtons(component.components);
      } else if (component.type === 'columns' && component.columns) {
        this.hideButtons(component.columns);
      } else {
        const isSubmitButton =
          component.type === 'button' &&
          (component.action === 'submit' || !component.action);
        if (isSubmitButton) {
          component.hidden = true;
        }
        if (component.type === 'toggle-button' && !component?.customOptions.showInWorkflowItem) {
          component.conditional.customConditional = 'show=false';
          component.customConditional = 'show=false';
          component.hidden = true;
        }
      }
    }
  }

  needsInterpolation(possibleDynamicValue: string): boolean {
    if (possibleDynamicValue) {
      const indexOfVariableStart = possibleDynamicValue.indexOf('{{');
      return (
        indexOfVariableStart > -1 &&
        possibleDynamicValue.indexOf('}}') > indexOfVariableStart + 1
      );
    }
    return false;
  }

  public addActionListeners(
    formInstance: any,
    components: any[],
    actionListeners: ActionListener[],
    formSubmission
  ) {
    if (!actionListeners || _.isEmpty(actionListeners)) {
      return;
    }

    actionListeners.forEach((actionListener) => {
      const widgetToListen = actionListener.widgetToListen;
      const eventName = actionListener.eventName;
      const actionChain = actionListener.actionChain;

      //let widget = FormioUtils.getComponent(form.components, widgetToListen, true);
      components.forEach((component) => {
        if (widgetToListen.includes(component.key)) {
          component.on(eventName, (eventData) => {
            this.handleActions(
              formInstance,
              actionChain,
              component,
              eventName,
              formSubmission,
              components,
              eventData
            );
          });
        } else if (
          component.components &&
          FormioUtils.isLayoutComponent(component)
        ) {
          this.addActionListeners(
            formInstance,
            component.components,
            actionListeners,
            formSubmission
          );
        } else if (component.type === 'columns' && component.columns) {
          this.addActionListeners(
            formInstance,
            component.columns,
            actionListeners,
            formSubmission
          );
        }
      });
    });
  }

  private async handleActions(
    formInstance: any,
    actionChain: ActionChain[],
    component: any,
    eventName: string,
    formSubmission: any,
    components: any[],
    eventData
  ) {
    let previousActionOutput = _.cloneDeep(formSubmission.data);
    let actionProcessorsResult = {};

    for (const action of actionChain) {
      const actionResponse = await this.handleAction(
        formInstance,
        action,
        previousActionOutput,
        formSubmission,
        components,
        eventData
      );
      const mergedData = _.merge(previousActionOutput, actionResponse);
      previousActionOutput = mergedData;
      actionProcessorsResult = _.merge(actionProcessorsResult, actionResponse);
      formInstance['actionProcessorsResult'] = actionProcessorsResult;
    }
  }

  private async handleAction(
    formInstance: any,
    action: ActionChain,
    resultAccumulator,
    formSubmission,
    components: any[],
    eventData
  ) {
    switch (action.actionType) {
      case ActionType.CALL_API:
        const apiConfig = _.clone(action.apiConfig);
        apiConfig.url = LodashTemplateEvaluator.interpolate(
          apiConfig.url,
          resultAccumulator
        );
        return this.handleApiCall(apiConfig, formSubmission);
      case ActionType.EXECUTE_SCRIPT:
        return this.executeScript(
          action.scriptExecutionConfig,
          resultAccumulator,
          formSubmission,
          formInstance,
          eventData
        );
      case ActionType.SET_FIELD_VALUES:
        return this.populateFields(
          action.fieldValuesConfig,
          resultAccumulator,
          formInstance.components,
          formSubmission
        );
      case ActionType.SHOW_POPUP:
        return this.displayPopup(
          action.popupConfig,
          resultAccumulator,
          formSubmission,
          eventData
        );
      case ActionType.REFRESH_SMART_TABLE:
        return this.refreshSmartTable(action.smartTableConfig, components);
    }
  }

  private async populateFields(
    fieldValuesConfig: FieldValues[],
    resultAccumulator,
    components: any[],
    formSubmission: any
  ) {
    return new Promise<any>((resolve, reject) => {
      for (const fieldValueConfig of fieldValuesConfig) {
        const fieldName = fieldValueConfig.fieldName;
        const component = FormRendererUtils.getComponent(fieldName, components);
        const fieldValue = fieldValueConfig.fieldValue;

        let valueToSet = _.get(resultAccumulator, fieldValue, '');
        if (valueToSet == null) {
          valueToSet = '';
        }
        console.log(`Setting value for fields ${fieldName} with variable ${fieldValue} and value as ${valueToSet}`);
        if (component && component.type == 'smarttable') {
          component.setValue(valueToSet);
        } else if (component) {
          component.setValue(valueToSet);
          component.redraw();
        } else {
          console.error(
            'No component found with name ',
            fieldName,
            '. Setting data.' + fieldName
          );
          _.set(formSubmission.data, fieldName, valueToSet);
        }
      }
      resolve({populateFields: 'executed'});
    });
  }

  private async executeScript(
    scriptExecutionConfig: ScriptExecutionConfig,
    resultAccumulator,
    formSubmission,
    formInstance,
    eventData
  ) {
    return new Promise<any>((resolve, reject) => {
      const scriptValue = LodashTemplateEvaluator.interpolate(
        scriptExecutionConfig?.scriptToExecute,
        formSubmission
      );
      const result = FormRendererUtils.evaluate(
        scriptValue,
        [
          {submission: formSubmission},
          {formInstance: formInstance},
          {eventData: eventData}
        ],
        'result',
        true
      );
      console.log('executeScript Result: ', result);
      resolve(result);
    });
  }

  private async displayPopup(
    popupConfig: any,
    resultAccumulator,
    formSubmission,
    eventData
  ) {
    const inputData = _.clone(formSubmission);
    inputData.eventData = eventData;
    inputData.otherResults = resultAccumulator;
    return this.showPopupWithPromise(popupConfig, inputData);
  }

  private async handleApiCall(
    apiConfig: ApiConfig,
    formSubmission: any
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      Formio.fetch(apiConfig.url, {
        //body: requestBody,
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + IamAuthUtils.getCurrentUserToken()
        },
        method: apiConfig.methodName
      }).then(function (response) {
        response.json().then(function (result) {
          if (apiConfig.responseContextVariable) {
            _.set(formSubmission, apiConfig.responseContextVariable, result);
          }
          resolve(result);
        });
      });
    });
  }

  prefixKeys(contextPrefix: any, components: any) {
    if (_.isArray(components)) {
      for (const component of components) {
        if (component.components && FormioUtils.isLayoutComponent(component)) {
          this.prefixKeys(contextPrefix, component.components);
        } else if (component.type === 'columns' && component.columns) {
          this.prefixKeys(contextPrefix, component.columns);
        } else {
          component.key = contextPrefix + '.' + component.key;
        }
      }
    } else {
      components.key = contextPrefix + '.' + components.key;
    }
  }

  private refreshSmartTable(
    smartTableConfig: SmartTableActionConfig,
    components: any[]
  ) {
    return new Promise((resolve, reject) => {
      this.reloadSmartTable(components, smartTableConfig.smartTableId);
      resolve("{'action': 'refreshed}");
    });
  }

  resolveDynamicLabels(components: any, formSubmission: any) {
    for (const component of components) {
      if (component.components && FormioUtils.isLayoutComponent(component)) {
        this.updateTitle(component, formSubmission);
        this.resolveDynamicLabels(component.components, formSubmission);
      } else if (component.type === 'columns' && component.columns) {
        this.updateTitle(component, formSubmission);
        this.resolveDynamicLabels(component.columns, formSubmission);
      } else {
        this.updateTitle(component, formSubmission);
      }
    }
  }

  resolveDynamicHtmlContent(components: any, formSubmission: any) {
    for (const component of components) {
      if (component.components && FormioUtils.isLayoutComponent(component)) {
        this.resolveDynamicHtmlContent(component.components, formSubmission);
      } else if (component.type === 'columns' && component.columns) {
        this.resolveDynamicHtmlContent(component.columns, formSubmission);
      } else if (component.type === 'htmlelement') {
        this.updateHtmlContent(component, formSubmission);
      }
    }
  }

  private updateTitle(component: any, formSubmission: any) {
    const hasLegendProperty =
      component.component && component.component.hasOwnProperty('legend');
    const hasTitleProperty =
      component.component && component.component.hasOwnProperty('title');
    const hasLabelProperty =
      component.component && component.component.hasOwnProperty('label');

    if (
      hasLegendProperty &&
      this.needsInterpolation(component.component.legend)
    ) {
      let legend = component.component.legend;
      legend = LodashTemplateEvaluator.interpolate(legend, formSubmission);
      component.component.legend = legend;
      component.redraw();
    }
    if (
      hasTitleProperty &&
      this.needsInterpolation(component.component.title)
    ) {
      let title = component.component.title;
      title = LodashTemplateEvaluator.interpolate(title, formSubmission);
      component.component.title = title;
      component.redraw();
    }
    if (
      hasLabelProperty &&
      this.needsInterpolation(component.component.label)
    ) {
      let label = component.component.label;
      label = LodashTemplateEvaluator.interpolate(label, formSubmission);
      component.component.label = label;
      component.redraw();
    }
  }

  showNestedFormsDiff(nestedFormsKey: string[], formInstance: any) {
    const firstNestedForm = FormRendererUtils.getComponent(
      nestedFormsKey[0],
      formInstance.components
    );
    const secondNestedForm = FormRendererUtils.getComponent(
      nestedFormsKey[1],
      formInstance.components
    );
    FormioUtils.eachComponent(
      firstNestedForm.components,
      (component) => {
        if (!FormioUtils.isLayoutComponent(component)) {
          const componentValue = _.get(formInstance, component.key);
          console.log('****', component.key, component.type, componentValue);
        }
      },
      true
    );
  }

  private updateHtmlContent(component: any, formSubmission: any) {
    console.log(component, formSubmission);
    if (component.component && component.component.attrs) {
      for (let i = 0; i < component.component.attrs.length; i++) {
        if (this.needsInterpolation(component.component.attrs[i].value)) {
          const rawValue = component.component.attrs[i].value;
          component.component.attrs[i].value = LodashTemplateEvaluator.interpolate(rawValue, formSubmission);
        }
        component.redraw();
      }
    }
    if (component?.component?.content) {
      const rawValue = component.component.content;
      component.component.content = LodashTemplateEvaluator.interpolate(rawValue, formSubmission);
    }
  }

  public interpolate(source: string, dataSource: any) {
    const str = source;
    let returnValue = "";
    if (source.includes("[") && source.includes("]")) {
      str.split("/").forEach(result => {
        if (result.includes("{{") && result.includes("}}")) {
          let parameterizedPart = result;
          parameterizedPart = parameterizedPart.replace("{{", "");
          parameterizedPart = parameterizedPart.replace("}}", "");
          returnValue = returnValue + "/" + _.get(dataSource, parameterizedPart, result);
        } else {
          returnValue = returnValue + "/" + result;
        }
      });
    } else {
      returnValue = LodashTemplateEvaluator.interpolate(
        str,
        dataSource
      )
    }

    return returnValue.replace(/^\/+/g, '');
  }

  resolveDataGridComponentAttributes(components, formData) {
    for (const component of components) {
      if (component.components && FormioUtils.isLayoutComponent(component)) {
        this.resolveDataGridComponentAttributes(component.components, formData);
      } else if (component.type === 'columns' && component.columns) {
        this.resolveDataGridComponentAttributes(component.columns, formData);
      } else if (component.type == 'embedded-report') {
        if (component.parent?.type == FormIODataTypes.DATA_GRID) {
          const rowIndex = component.rowIndex;
          console.log(component.key, component.customOptions?.analyticsConfig);
          const analyticsConfig: AnalyticsConfig = component.component.customOptions.analyticsConfig;
          if (analyticsConfig && analyticsConfig.embeddedDashboardId
            && this.needsInterpolation(analyticsConfig.embeddedDashboardId)) {
            analyticsConfig.embeddedDashboardId = analyticsConfig.embeddedDashboardId.replaceAll("rowIndex", rowIndex);
            analyticsConfig.embeddedDashboardId = this.interpolate(analyticsConfig.embeddedDashboardId, formData);
          }
        }
      } else {
        console.log(component);
      }
    }
  }

  public cleanupRequestData(submissionData: any, formInstance: any) : any {
    let dataCopy = _.cloneDeep(submissionData);
    for (const component of formInstance.components) {
      if (component.components && FormioUtils.isLayoutComponent(component)) {
        dataCopy = this.cleanupRequestData(dataCopy, component);
      } else if (component.type === 'columns' && component.columns) {
        dataCopy = this.cleanupRequestData(dataCopy, component);
      } else {
        if ( (component.persistent && component.persistent !== true)
                || component.type == 'screen-configuration'
                    || (component.component && component.component.persistent !== true)) {
          delete dataCopy[component.key];
        }
      }
    }
    return dataCopy;
  }

  private hideComponent(component: any) {
   if(component) {
     component.hidden = true;
     console.log("Hiding Component : ", component.label)}
  }
}
