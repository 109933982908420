import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {SpinnerUtil} from "@shared/utils/spinner-util";


@Injectable()
export class LoaderService {
  isLoading = new Subject<boolean>();
  show() {
    this.isLoading.next(true);
    SpinnerUtil.showLoader()
  }
  hide() {
    SpinnerUtil.hideLoader()
    this.isLoading.next(false);
  }
}
